import styled from "@emotion/native";
import { LinearGradient } from "expo-linear-gradient";
import React, { FC } from "react";
import { useNavigation } from "@react-navigation/native";
import colors from "../colors";
import { Button, SafeArea, Text } from "../components";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";

const LandingScreen: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();

  return (
    <SafeArea>
      <Header>
        <Logo source={require("../assets/images/header-logo.png")} />
      </Header>
      <Content colors={["#ff0000", "#820000"]} start={[0, 0.83]} end={[1, 0]}>
        <LandingImage source={require("../assets/images/landing.png")} />
        <ContentWrap style={{ height: isDesktop ? 600 : 360 }}>
          <Left></Left>
          <Right>
            <DigicraftText bold>{t("digicraft")}</DigicraftText>
            <TitleText style={{ width: isDesktop ? "100%" : 236 }}>
              {t("landing.title")}
            </TitleText>
            <Button secondary onPress={() => navigation.navigate("EnterKey")}>
              {t("landing.start")}
            </Button>
          </Right>
        </ContentWrap>
      </Content>
    </SafeArea>
  );
};

export default LandingScreen;

const Header = styled.View`
  align-items: center;
  height: 70px;
  justify-content: center;
`;

const Logo = styled.Image`
  height: 28px;
  width: 334px;
`;

const Content = styled(LinearGradient)`
  flex: 1;
  justify-content: center;
`;

const ContentWrap = styled.View`
  display: flex;
  flex-direction: row;
`;

const Left = styled.View`
  flex: 1;
`;

const LandingImage = styled.Image`
  flex: 1;
  resize-mode: contain;
  width: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

const Right = styled.View`
  align-items: flex-start;
  flex: 1;
  justify-content: center;
`;

const DigicraftText = styled(Text)`
  font-size: 30px;
  color: ${colors.white};
  margin-bottom: 10px;
`;

const TitleText = styled(Text)`
  font-size: 44px;
  line-height: 48px;
  color: ${colors.white};
  margin-bottom: 30px;
`;
