import styled from "@emotion/native";
import React, { FC, useEffect, useState } from "react";
import { IRightWrongTextActivityContent } from "../../types";
import Options from "../Options";
import RightWrongDrawer from "../RightWrongDrawer";

export interface IRightWrongTextActivityProps {
  content: IRightWrongTextActivityContent;
  onCompleteChange: (complete: boolean, success: boolean) => void;
}

const RightWrongTextActivity: FC<IRightWrongTextActivityProps> = ({
  content,
  onCompleteChange,
}) => {
  const [wrongSelected, setWrongSelected] = useState(false);
  const [assignedValues, setAssignedValues] = useState<{
    [area: number]: boolean | undefined;
  }>({});

  useEffect(() => {
    const success = content.options.every(
      (option, i) => option.isRight === assignedValues[i]
    );
    onCompleteChange(
      Object.values(assignedValues).filter((v) => v !== undefined).length === 4,
      success
    );
  }, [assignedValues]);

  const onOptionPress = (index: number) => {
    if (assignedValues[index] === !wrongSelected) {
      setAssignedValues({
        ...assignedValues,
        [index]: undefined,
      });
    } else {
      setAssignedValues({
        ...assignedValues,
        [index]: !wrongSelected,
      });
    }
  };

  return (
    <Container>
      <Options
        options={content.options.map((option, i) => ({
          text: option.text,
          right: assignedValues[i] === true,
          wrong: assignedValues[i] === false,
        }))}
        onOptionPress={onOptionPress}
      />
      <RightWrongDrawer
        wrongSelected={wrongSelected}
        onChange={setWrongSelected}
      />
    </Container>
  );
};

export default RightWrongTextActivity;

const Container = styled.View`
  flex: 1;
`;
