import { useMutation } from "@apollo/client";
import styled from "@emotion/native";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { FC, useEffect, useState } from "react";
import colors from "../colors";
import { endingText } from "../config";
import { Button, ErrorMessage, SafeArea, Text, ExitModal } from "../components";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";
import { StackParamList } from "../types";
import { Dimensions, ImageBackground } from "react-native";
import { SUBMIT_RESULTS_MUTATION } from "../queries";

const EndingScreen: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<StackParamList, "Ending">>();
  const adventure = params.adventure;
  const playersNumber = params.playersNumber;
  const [error, setError] = useState<boolean>(false);
  const [exitVisible, setExitVisible] = useState<boolean>(false);
  const [submitResults, { error: apolloError }] = useMutation(
    SUBMIT_RESULTS_MUTATION
  );

  useEffect(() => {
    submitResults({
      variables: {
        code: params.key,
        age: params.age,
        numPlayers: params.playersNumber,
        activities: params.activities.map(
          (activity) => activity.id as unknown as string
        ),
        results: params.results,
      },
    });
  }, []);

  useEffect(() => setError(!!apolloError), [apolloError]);

  const height = Dimensions.get("window").height;
  const width = Dimensions.get("window").width;

  const imageBackground =
    adventure === "initial"
      ? require("../assets/images/ending_adventure_1.png")
      : require("../assets/images/ending_adventure_2.png");

  return (
    <ImageBackground source={imageBackground} style={{ height, width }}>
      <SafeArea>
        <Container>
          <FooterWrap style={{ padding: isDesktop ? 40 : 20 }}>
            <Footer style={{ elevation: 3 }}>
              <StyledText>{t(endingText[adventure])}</StyledText>
              <Button
                iconName="arrow-small"
                onPress={() =>
                  navigation.navigate("UnlockRewards", { ...params })
                }
              >
                {t("general.continue-button")}
              </Button>
            </Footer>
          </FooterWrap>
          <ErrorMessage visible={error} onClose={() => setError(false)} />
        </Container>
      </SafeArea>
      <ExitModal
        plural={playersNumber > 1}
        visible={exitVisible}
        setVisible={setExitVisible}
      />
    </ImageBackground>
  );
};

export default EndingScreen;

const Container = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
  overflow: hidden;
`;

const Footer = styled.View`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 10px;
  flex: 1;
  flex-direction: row;
  padding: 20px;
`;

const FooterWrap = styled.View`
  flex-direction: row;
  bottom: 0;
  position: absolute;
  z-index: 0;
`;

const StyledText = styled(Text)`
  flex-shrink: 1;
  margin-right: 20px;
`;
