import styled from "@emotion/native";
import React, { FC, useState } from "react";
import InsetShadow from "react-native-inset-shadow";
import { TouchableOpacity, View } from "react-native";
import colors from "../colors";
import { Anchor } from "../types";
import Text from "./Text";
import { DraxView } from "react-native-drax";

export interface ILabelBalloonProps {
  id?: string;
  anchor: Anchor;
  text?: string;
  left: number;
  top: number;
  width: number;
  selected?: boolean;
  draggable?: boolean;
  onPress?: () => void;
  onDrop?: (droppingLabelText: string) => void;
  setDraggingLabel?: (dragginLabel: string) => void;
  onFreeBalloon?: (ballonLabel: string) => void;
  setDraggingLabelFromBallon?: (ballonLabel: string) => void;
  setDraggingOutOfOrigin?: (draggingOut: boolean) => void;
}

const LabelBalloon: FC<ILabelBalloonProps> = ({
  id,
  anchor,
  text,
  left,
  top,
  width,
  selected,
  draggable,
  onPress,
  onDrop,
  setDraggingLabel,
  onFreeBalloon,
  setDraggingLabelFromBallon,
  setDraggingOutOfOrigin,
}) => {
  const labelWidth = width || 120;
  const [hoverStyleOn, setHoverStyleOn] = useState<boolean>(false);
  const [isDragging, setisDragging] = useState<boolean>(false);

  let translateX = 0;
  let translateY = 0;
  let triangleStyles = {};

  switch (
    anchor //Most of this numbers are from try-error method
  ) {
    case Anchor.top:
      translateX = -(labelWidth / 2);
      triangleStyles = {
        left: "50%",
        top: -9,
        borderLeftWidth: 0.5,
        borderLeftColor: colors.white,
        borderTopWidth: 0.5,
        borderTopColor: colors.white,
      };
      break;
    case Anchor.topRight:
      translateX = 32 - labelWidth;
      translateY = 12;
      triangleStyles = {
        right: 20,
        top: -9,
        borderLeftWidth: 0.5,
        borderLeftColor: colors.white,
        borderTopWidth: 0.5,
        borderTopColor: colors.white,
      };
      break;
    case Anchor.right:
      translateX = -labelWidth - 12;
      translateY = -32;
      triangleStyles = {
        right: -10,
        top: 22,
        borderTopWidth: 0.5,
        borderTopColor: colors.white,
        borderRightWidth: 0.5,
        borderRightColor: colors.white,
      };
      break;
    case Anchor.bottomRight:
      translateX = 32 - labelWidth;
      translateY = -64;
      triangleStyles = {
        right: 20,
        bottom: -10,
        borderRightWidth: 0.5,
        borderRightColor: colors.white,
        borderBottomWidth: 0.5,
        borderBottomColor: colors.white,
      };
      break;
    case Anchor.bottom:
      translateX = -(labelWidth / 2);
      translateY = -64;
      triangleStyles = {
        left: "50%",
        bottom: -10,
        borderRightWidth: 0.5,
        borderRightColor: colors.white,
        borderBottomWidth: 0.5,
        borderBottomColor: colors.white,
      };
      break;
    case Anchor.bottomLeft:
      translateX = -32;
      translateY = -64;
      triangleStyles = {
        left: 20,
        bottom: -10,
        borderRightWidth: 0.5,
        borderRightColor: colors.white,
        borderBottomWidth: 0.5,
        borderBottomColor: colors.white,
      };
      break;
    case Anchor.left:
      translateX = 12;
      translateY = -32;
      triangleStyles = {
        left: -9,
        top: 20,
        borderLeftWidth: 0.5,
        borderLeftColor: colors.white,
        borderBottomWidth: 0.5,
        borderBottomColor: colors.white,
      };
      break;
    case Anchor.topLeft:
      translateX = -32;
      translateY = 12;
      triangleStyles = {
        left: 20,
        top: -9,
        borderLeftWidth: 0.5,
        borderLeftColor: colors.white,
        borderTopWidth: 0.5,
        borderTopColor: colors.white,
      };
      break;
  }

  return (
    <Container
      underlayColor={colors.turquoise}
      style={{
        left,
        top,
        marginTop: translateY,
        marginLeft: translateX,
      }}
    >
      <TouchableOpacity activeOpacity={1} onPress={onPress}>
        {text ? (
          <CustomDraxView
            renderContent={() => (
              <Label
                style={{
                  backgroundColor: isDragging
                    ? colors.lightGray
                    : selected || hoverStyleOn
                    ? colors.turquoise
                    : colors.white,
                }}
              >
                {isDragging ? (
                  <DraxView // This component is for when the user drop the label in the same place as before
                    style={{ height: "100%" }}
                    renderContent={() => (
                      <InsetShadow
                        elevation={1}
                        top={true}
                        shadowOpacity={0.5}
                        shadowOffset={1}
                        containerStyle={{
                          flex: 1,
                          position: "relative",
                          borderRadius: 10,
                        }}
                      >
                        {/* The text, even if it's invisible in the view, is for setting the width of the balloon */}
                        <LabelText
                          style={{
                            color: isDragging ? colors.lightGray : colors.black,
                          }}
                          numberOfLines={1}
                        >
                          {text}
                        </LabelText>
                      </InsetShadow>
                    )}
                    onReceiveDragEnter={() => setHoverStyleOn(true)}
                    onReceiveDragExit={() => setHoverStyleOn(false)}
                    onReceiveDragDrop={(event) => {
                      onDrop && onDrop(event.dragged.payload);
                      setHoverStyleOn(false);
                    }}
                  />
                ) : (
                  <LabelText
                    style={{
                      color: isDragging ? colors.lightGray : colors.black,
                    }}
                    numberOfLines={1}
                  >
                    {text}
                  </LabelText>
                )}
              </Label>
            )}
            renderHoverContent={() => (
              <Label
                style={{
                  backgroundColor: colors.turquoise,
                }}
              >
                <LabelText numberOfLines={1}>{text}</LabelText>
              </Label>
            )}
            onReceiveDragEnter={() => {
              setHoverStyleOn(true);
              isDragging &&
                setDraggingOutOfOrigin &&
                setDraggingOutOfOrigin(false);
            }}
            onReceiveDragExit={() => {
              setHoverStyleOn(false);
            }}
            onReceiveDragDrop={(event) => {
              onDrop && onDrop(event.dragged.payload);
              setHoverStyleOn(false);
            }}
            onDragExit={() => {
              isDragging &&
                setDraggingOutOfOrigin &&
                setDraggingOutOfOrigin(true);
            }}
            dragPayload={text}
            longPressDelay={100}
            onDragStart={() => {
              setDraggingLabel && setDraggingLabel(text);
              setDraggingLabelFromBallon &&
                id &&
                setDraggingLabelFromBallon(id);
              setDraggingOutOfOrigin && setDraggingOutOfOrigin(false);
              setisDragging(true);
            }}
            onDragEnd={() => {
              setDraggingLabel && setDraggingLabel("");
              onFreeBalloon && id && onFreeBalloon(id);
              setisDragging(false);
            }}
            onDragDrop={() => {
              setDraggingLabel && setDraggingLabel("");
              setisDragging(false);
            }}
            draggingStyle={{
              backgroundColor: colors.lightGray,
              color: colors.lightGray,
            }}
            hoverDragReleasedStyle={{ opacity: 0 }}
            draggable={draggable}
          />
        ) : (
          <Placeholder
            style={{
              backgroundColor:
                selected || hoverStyleOn ? colors.turquoise : colors.lightGray,
            }}
          >
            <InsetShadow
              elevation={1}
              top={true}
              shadowOpacity={0.5}
              shadowOffset={1}
              containerStyle={{ flex: 1, position: "relative" }}
            >
              <DraxView
                style={{ height: "100%" }}
                renderContent={() => <View />}
                onReceiveDragEnter={() => setHoverStyleOn(true)}
                onReceiveDragExit={() => setHoverStyleOn(false)}
                onReceiveDragDrop={(event) => {
                  onDrop && onDrop(event.dragged.payload);
                  setHoverStyleOn(false);
                }}
              />
            </InsetShadow>
          </Placeholder>
        )}
      </TouchableOpacity>
      <Triangle
        style={{
          ...triangleStyles,
          backgroundColor:
            selected || hoverStyleOn
              ? colors.turquoise
              : text
              ? colors.white
              : colors.lightGray,
          transform: [{ rotate: "45deg" }],
          ...(hoverStyleOn && !!text
            ? {
                borderTopColor: colors.turquoise,
                borderRightColor: colors.turquoise,
                borderBottomColor: colors.turquoise,
                borderLeftColor: colors.turquoise,
              }
            : {}),
          ...(isDragging
            ? {
                backgroundColor: colors.lightGray,
              }
            : {}),
        }}
      />
    </Container>
  );
};

export default LabelBalloon;

const Container = styled.View`
  position: absolute;
`;

const Placeholder = styled.View`
  border-color: ${colors.white};
  border-width: 1px;
  border-radius: 10px;
  height: 64px;
  overflow: hidden;
  width: 120px;
`;

const Label = styled.View`
  elevation: 5;
  border-radius: 10px;
  height: 64px;
  justify-content: center;
`;

const Triangle = styled.View`
  position: absolute;
  background-color: ${colors.lightGray};
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
`;

const CustomDraxView = styled(DraxView)`
  border-radius: 10px;
  height: 64px;
`;

const LabelText = styled(Text)`
  color: ${colors.black};
  font-size: 18px;
  margin: 0 24px;
`;
