import { API_HOST } from "@env";
import { Platform } from "react-native";
import Constants from "expo-constants";
import * as Updates from "expo-updates";
import { Avatar, IPlayer, IReward } from "./types";
import colors from "./colors";

export const getUri = (env = Updates.releaseChannel) => {
  // __DEV__ is true when run locally, but false when published.
  if (__DEV__ || Platform.OS === "web") {
    return API_HOST;
  } else if (env === "dev") {
    return Constants.manifest.extra.API_HOST.dev;
  } else if (env === "sta") {
    return Constants.manifest.extra.API_HOST.sta;
  } else {
    return Constants.manifest.extra.API_HOST.prod;
  }
};

export const beginningText = {
  initial: "beginning.initial",
  final: "beginning.final",
};

export const endingText = {
  initial: "ending.initial",
  final: "ending.final",
};

export const cards: IReward[] = [
  {
    color: colors.orange,
    source: require("./assets/images/card_1.png"),
    thumbnail: require("./assets/images/thumbnail-card_1.png"),
  },
  {
    color: colors.yellow,
    source: require("./assets/images/card_2.png"),
    thumbnail: require("./assets/images/thumbnail-card_2.png"),
  },
  {
    color: colors.purple,
    source: require("./assets/images/card_3.png"),
    thumbnail: require("./assets/images/thumbnail-card_3.png"),
  },
  {
    color: colors.blue,
    source: require("./assets/images/card_4.png"),
    thumbnail: require("./assets/images/thumbnail-card_4.png"),
  },
  {
    color: colors.yellow,
    source: require("./assets/images/card_5.png"),
    thumbnail: require("./assets/images/thumbnail-card_5.png"),
  },
  {
    color: colors.blue,
    source: require("./assets/images/card_6.png"),
    thumbnail: require("./assets/images/thumbnail-card_6.png"),
  },
  {
    color: colors.orange,
    source: require("./assets/images/card_7.png"),
    thumbnail: require("./assets/images/thumbnail-card_7.png"),
  },
  {
    color: colors.lightGreen,
    source: require("./assets/images/card_8.png"),
    thumbnail: require("./assets/images/thumbnail-card_8.png"),
  },
  {
    color: colors.lightGreen,
    source: require("./assets/images/card_9.png"),
    thumbnail: require("./assets/images/thumbnail-card_9.png"),
  },
];

export const characters: IPlayer[] = [
  {
    avatar: Avatar.Amira,
    color: colors.purple,
    image: require("./assets/images/avatar-amira.png"),
    imageGray: require("./assets/images/avatar-amira_gray.png"),
    name: "amira",
  },
  {
    avatar: Avatar.Friend,
    color: colors.yellow,
    image: require("./assets/images/avatar-friend.png"),
    imageGray: require("./assets/images/avatar-friend_gray.png"),
    name: "friend",
  },
  {
    avatar: Avatar.Leo,
    color: colors.blue,
    image: require("./assets/images/avatar-leo.png"),
    imageGray: require("./assets/images/avatar-leo_gray.png"),
    name: "leo",
  },
  {
    avatar: Avatar.Monster,
    color: colors.lightGreen,
    image: require("./assets/images/avatar-monster.png"),
    imageGray: require("./assets/images/avatar-monster_gray.png"),
    name: "monster",
  },
  {
    avatar: Avatar.Robot,
    color: colors.orange,
    image: require("./assets/images/avatar-robot.png"),
    imageGray: require("./assets/images/avatar-robot_gray.png"),
    name: "robot",
  },
];

export const defaultLanguage = "es";

export const diplomas: IReward[] = [
  {
    color: colors.white,
    source: require("./assets/images/diploma_1.png"),
    thumbnail: require("./assets/images/thumbnail-diploma_1.png"),
  },
  {
    color: colors.white,
    source: require("./assets/images/diploma_2.png"),
    thumbnail: require("./assets/images/thumbnail-diploma_2.png"),
  },
  {
    color: colors.white,
    source: require("./assets/images/diploma_3.png"),
    thumbnail: require("./assets/images/thumbnail-diploma_3.png"),
  },
];
