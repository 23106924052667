import styled from "@emotion/native";
import { useNavigation } from "@react-navigation/native";
import React, { FC } from "react";
import { Dimensions, Linking, Platform, ScrollView, View } from "react-native";
import colors from "../colors";
import { BulletPoint, Header, Icon, SafeArea, Text } from "../components";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";

interface ISection {
  link?: string;
  text: string;
  title: string;
  subsections?: string[];
}

const HelpScreen: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();

  const width = Dimensions.get("window").width - 2 * (isDesktop ? 150 : 20);

  return (
    <ScrollView contentContainerStyle={{ paddingBottom: 20 }}>
      <SafeArea>
        <Header
          rightButton={{
            iconName: "close",
            onPress: () => navigation.goBack(),
          }}
        />
        <Container style={Platform.OS !== "web" && { flex: 1 }}>
          <Title
            style={{
              marginBottom: isDesktop ? 40 : 46,
              marginTop: isDesktop ? 40 : 20,
            }}
          >
            {t("help.title")}
          </Title>
          <View style={{ width }}>
            {((t("help.sections") as any) as ISection[]).map(
              (section: ISection, keySection: number) => (
                <Section key={keySection}>
                  <SectionIcon name="messages" />
                  <SectionContent>
                    <SectionTitle>{section.title}</SectionTitle>
                    <StyledText>
                      <>{section.text}</>
                      {section.link && (
                        <>
                          <>{' "'}</>
                          <Text
                            onPress={() =>
                              Linking.openURL(`https://${section.link}`)
                            }
                          >{`www.${section.link}`}</Text>
                          <>{'".'}</>
                        </>
                      )}
                    </StyledText>
                    {section.subsections &&
                      section.subsections.map(
                        (subsection: string, keySubsection: number) => (
                          <BulletPoint
                            key={keySubsection}
                            index={keySubsection}
                          >
                            {subsection}
                          </BulletPoint>
                        )
                      )}
                  </SectionContent>
                </Section>
              )
            )}
          </View>
        </Container>
      </SafeArea>
    </ScrollView>
  );
};

export default HelpScreen;

const Container = styled.View`
  align-items: center;
  justify-content: center;
`;

const Section = styled.View`
  flex-direction: row;
  margin-bottom: 40px;
`;

const SectionContent = styled.View`
  flex-shrink: 1;
`;

const SectionIcon = styled(Icon)`
  color: ${colors.red};
  height: 60px;
  margin-right: 14px;
  min-width: 60px;
`;

const SectionTitle = styled(Text)`
  color: ${colors.red};
  font-size: 26px;
  margin-bottom: 10px;
`;

const StyledText = styled(Text)`
  flex-shrink: 1;
`;

const Title = styled(Text)`
  color: ${colors.red};
  font-size: 44px;
  margin: 40px 0;
`;
